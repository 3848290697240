/* Type -------------------- */

// TEMP:
.lead > a {
  text-decoration: underline orange solid;

  &:hover {
    text-decoration-color: springgreen;
  }
}
