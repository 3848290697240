/* Bootstrap Overrides -------------------- */

$navbar-dark-color: dodgerblue;
// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            true !default;
$enable-shadows:            true;
$enable-transitions:        true;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;
$enable-print-styles:       true;

// Variables
//
// Colors

$theme-colors: (
        primary: red,
        secondary: #FCBE00,
        light: $light,
        white: $white,
        dark: $dark,
        info: $litegray,
        success: $darkgreen,
        warning: $yellow,
        danger: $danger
);

$body-bg: $white;
$body-color: $dark2;


$body-color-inverse: invert($body-color) !default;
$link-color: $green2;
$border-color: $blue2;


// Fonts
$font-family-base: "Raleway-Regular",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$headings-font-family: $font-family-base;
$display-font-family: $font-family-base;
$font-weight-normal: 200;
$headings-font-weight: 200;
$lead-font-size:   1.30rem;

$spacer: 1.5rem;


.font-weight-bold{
  font-family: 'Proxima Nova Bold';
}


.modal-open .modal {
 // display: flex!important;
  align-items: center!important;
  .modal-dialog {
    flex-grow: 1;
  }
}

.modal-backdrop{
  background-color: #ffffff !important;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}
.modal-open .container-fluid, .modal-open  .container {
  -webkit-filter: blur(5px) grayscale(90%);
}


.modal-header .close{
  margin: 0 !important;
  padding-right: 5px !important;
}

.close span{
  display: none;
}
