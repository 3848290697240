.bg-gradient-primary-to-secondary {
  background-color: white;
  background-image: none;
}


.navbar-marketing .navbar-brand img {
  width: 110px;
  height: 100%;
}


.headerx h5{
  color: white;
}

.page-header .page-header-content .page-header-title {
  font-size: 4.0rem;
  font-weight: bold;
  font-family: "Raleway-Bold", serif;
}
.badge-sm {
  font-size: 0.75rem;
}

 .badge-marketing {
   padding: 10px;
 }

 .badge-light {
   color: #1f2d41;
   background-color: #F0861E;
   font-weight: bold;
   height: 30px;
   width: 30px;
   position: relative;
   top: -20px;
   z-index: 999999999999999999999999;
   left: -147px;
 }
 .treeseps h3{
   font-weight: bold;
   font-size: 22px;
   text-align: left;
 }

.treeseps{
  margin-top: -60px;
}
h1{
  font-size: 3.0rem;
  font-weight: bold;
}
.badgex{
  background: #F0861E;
  color: white;
  text-transform: uppercase;
  text-align: center;
  padding: 4px 15px;
  border-radius: 10px;
  font-size: 11px;
  letter-spacing: 1px;
  font-family: "Raleway-Bold", serif;
}
.badgey{
  background: white;
  color: black;
  text-transform: uppercase;
  text-align: center;
  padding: 4px 15px;
  font-size: 11px;
  letter-spacing: 1px;
  border-radius: 10px;
}

.xselected{
  color: #F0861E !important;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link-different:hover{
  color: #05A89F !important;
}
.navbar-scrolled a {
  color: white !important;
}
.yselected{
  color: #05A89F !important;
}
.solution .card{
background: #F0861E;
}

.solution h6{
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 3px;
}
.createbetter h3{
  text-align: left;
  color: white;
  font-weight: bold;
  font-size: 25px;
}


.methodologie ul {
  list-style-type: upper-roman;
  font-weight: bold;
}
.methodologie ul ul{
  list-style: none;
}
.methodologie h2{
font-weight: bold;
}
.subul li{
  font-weight: normal;
}
ul{
  padding-left: 0;
}

h2,h3,h4,h5{
  font-weight: bold;

}

h3,h1,.font-weight-bold,.methodologie h2,{
  font-family: "Raleway-Bold", serif;
}

.staistfied h1{
  color: white;
}

.bg-bege{
  background: #363E49;
}

.quotex{
  font-weight: bold;

  position: absolute;
  top: -20px;
  z-index: 9999999;
}
.review{
  font-size: 12px;
  text-align: left;
  padding: 4px 11px;
  color: black;
}
.reviewsub{
  color: black;
}
.contactbg{
  background: url("../images/rectangle.png");
  background-repeat: no-repeat;
  background-position-y: 39%;
}
.fullcontactform{
  margin-top: -201px;
}

.socientadetails p{
  text-align: left;

}
.bold{
  color:black;
}
.socientadetails ul{
  margin-left: 18px;
  font-weight: bold;
  font-size: 19px;
  color: black;
}

.socientadetails li{
color: #656B76;
  font-weight: normal;
}
.onreview ,.onreview h5{
  color: white;
}

.segmented{
  position: relative;
  margin-top: -153px;
}
.cursor{
  cursor: pointer;
}

.segmented .selected,.segmented a:hover , .feabenf .selected,.feabenf a:hover{

  border-bottom: 3px solid black;
}

.contactbg .lead , .contactbg h3 {
  color: white;
}

 .footer a {
  padding: 13px;
}

 .whitelogo{
   display: none;
 }

 .navbar-scrolled .whitelogo{
   display: block;
 }
.navbar-scrolled .blacklogo{
  display: none;
}

.react-multi-carousel-list{
  overflow: visible;
}

.boldx{
  color: black !important;
}
.reviewername{
  font-size: 16px;
}


.react-multiple-carousel__arrow {
  margin-top: 342px;
  border-radius: 0;
}

.react-multiple-carousel__arrow--left {
  right: calc(4% + 45px);
  left: auto;
}

.productname{
font-size: 30px;
}
.feabenf h2 a{
  color: #D9DADC !important;
  font-family: "Raleway-Light", serif;
}

.feabenf h2 a.selected{
  color: black !important;
}
.methodologie h2{
  letter-spacing: 1px;
  font-size: 18px;
}

.onreview .review{
  color: white;
}

.socientadetails{
  top: 93px;
}
.react-multiple-carousel__arrow--right,.react-multiple-carousel__arrow--right:hover{
  background: url("../images/right.png");
  background-repeat: no-repeat;
  width: 60px;
  opacity: 0.6;
}
.react-multiple-carousel__arrow--left,.react-multiple-carousel__arrow--left:hover{
  background: url("../images/left.png");
  background-repeat: no-repeat;
  width: 60px;
  opacity: 0.6;
}
.react-multiple-carousel__arrow--left:hover{
  opacity: 1;
}
.react-multiple-carousel__arrow--right:hover{
  opacity: 1;
}

.react-multiple-carousel__arrow--right::before,.react-multiple-carousel__arrow--left::before {
  content: "";
}

.subfooter a{
  color: #A2A1A1 !important;
  font-weight: bold;
}

.subfooter a{
  padding: 13px;
  padding-left: 20px;
  padding-right: 20px;
}

