@media only screen and (max-width: 768PX){

  .whitelogo{
    display: block !important;
  }
  .blacklogo{
    display: none;
  }

  .reviewsource{
    position: relative;
    top: -8px;
    left: 41px;
    font-size: 14px;
  }

  .reviewsource .reviewsub {
    width: 190px;
  }
  .reviewfixedh {
    height: 250px;
  }
  .reviewimg{
    width: 88px !important;
  }
  #root{
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 600px){

}
