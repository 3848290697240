@font-face {
  font-family: 'Raleway Black';
  src: local('Raleway Black'), url('../fonts/Raleway-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway-Bold';
  src: local('Raleway-Bold'), url('../fonts/Raleway-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway-Regular';
  src: local('Raleway-Regular'), url('../fonts/Raleway-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Proxima Nova Extrabold';
  src: local('Proxima Nova Extrabold'), url('../fonts/Raleway-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-Light';
  src: local('Raleway-Light'), url('../fonts/Raleway-Light.ttf') format('truetype');
}
