$gray: #C6C6C6;
$dark: #383F41;
$dark2:#333;
$light: #f3f3f3;
$white: #fff;
$gray2: #ccc;
$litegray:#F5F5F5;
$lightwhite: #dc354;
$blue: #233E85;
$blue2: #5EBBF5;
$green: #7CB233;
$green2:#12bbad;
$darkgreen: #28a745;
$yellow: #ffc107;
